import * as React from "react";

import { SEO } from "../seo/seo"

import Layout from "../layouts";

const AboutPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO/>
    </Layout>
  );
};

export default AboutPage;
